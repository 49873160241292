.bigProgress {
    width: 120px;
    height: 120px;
}

.pad30 {
  padding: 30px;
}

.floatRight {
  float: right;
}